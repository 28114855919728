// utils
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CardRenderer from '@/components/questionnaire/Home/CardRenderer';
// components
import ToDoListItem from '@/components/questionnaire/Home/ToDoListItem';
// list items
import { todoListItemKeys, todoListItems } from '@/components/questionnaire/Home/todoListConfig';
// MUI
import { useQuestionnaireVisibility } from '@/hooks/response/useQuestionnaireVisibility';
import { PhasesEnum } from '@/types/discloser';
import { OrganisationTypeIdService } from '@/types/organisation.type.id.service';
// types
import { ToDoListItemType } from '@/types/response';
import { Box, List, Typography } from '@mui/material';
import { ToDoListProps } from './types';

export default function ToDoList({ phaseName, organisationTypeId }: ToDoListProps) {
  const { t } = useTranslation('questionnaire', { keyPrefix: 'questionnaire.home.toDoList' });
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { isQuestionnaireEditable } = useQuestionnaireVisibility();

  const onClick = (toDoItem: ToDoListItemType) => {
    if (
      phaseName === PhasesEnum.TRACK &&
      !(
        organisationTypeId === OrganisationTypeIdService.CITY() ||
        organisationTypeId === OrganisationTypeIdService.STATES_AND_REGIONS() ||
        organisationTypeId === OrganisationTypeIdService.COMPANY()
      )
    ) {
      enqueueSnackbar(t('errors.accountSetup'), { variant: 'error' });
      return;
    }

    if (toDoItem.newTab && toDoItem.link) {
      window.open(toDoItem.link, '_blank');
    } else if (toDoItem.link) {
      router.push({
        pathname: toDoItem.link,
      });
    }
  };

  /**
   * Only allow editing of questionnaire setup
   * if the questionnaire is in an editable state
   * otherwise, hide the 'Access Setup' item
   **/
  const filteredItems = isQuestionnaireEditable
    ? todoListItems
    : todoListItems.filter(item => item.title !== todoListItemKeys.accessSetup);

  return (
    <CardRenderer>
      <Box sx={{ display: 'flex', pb: 1 }}>
        <Typography
          sx={{ pl: 1 }}
          variant="subtitle1"
        >
          {t('title')}
        </Typography>
      </Box>
      <List sx={{ listStyleType: 'disc', pl: 4, pt: 0 }}>
        {filteredItems.map(item => (
          <ToDoListItem
            key={t(item.title)}
            title={item.title}
            link={item.link}
            onClick={() => onClick(item)}
            newTab={item.newTab}
          />
        ))}
      </List>
    </CardRenderer>
  );
}
