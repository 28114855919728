import { Trans, useTranslation } from 'react-i18next';
import Loading from '@/components/common/loading/Loading';
import { ProductCard } from '@/components/home/Authority/Products/ProductCard';
import { GET_ORGANISATION_PRODUCTS } from '@/graphql/disclosure/queries';
import { GetOrganisationFeeProductsQuery } from '@/lib/discloser/__generated__/graphql';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { useQuery } from '@apollo/client';
import { commonConfig } from '@config';
import { ChevronRightOutlined } from '@mui/icons-material';
import { Box, Button, Grid, Link, Stack, Typography } from '@mui/material';

type ProductListProps = {
  setIsSelectionSkipped: (value: boolean) => void;
  setIsFreeProductSelected: (value: boolean) => void;
};

export const ProductList = ({ setIsSelectionSkipped, setIsFreeProductSelected }: ProductListProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'common.productsList' });

  const { data, loading: productsLoading } = useQuery<GetOrganisationFeeProductsQuery>(GET_ORGANISATION_PRODUCTS, {
    client: disclosureClient,
    onError: onApolloError,
  });

  if (productsLoading) {
    return <Loading />;
  }

  const skipProductSelection = async () => {
    setIsSelectionSkipped(true);
  };

  return (
    <Stack
      gap={2}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <Box>
        <Typography
          variant="h3"
          component="h1"
          mb={4}
        >
          {t('header')}
        </Typography>
        <Typography
          variant="h5"
          component="p"
          mb={1}
        >
          {t('title')}
        </Typography>
        <Typography variant="body2">{t('description1')}</Typography>
        <Typography variant="body2">
          <Trans
            i18nKey={'common.productsList.description2'}
            components={[
              <Link
                target="_blank"
                href={commonConfig.cdpFee}
                key="adminFeeFaqLink"
                color="inherit"
                underline="always"
                sx={{ cursor: 'pointer' }}
              />,
            ]}
          />
        </Typography>
      </Box>
      <Button
        variant="text"
        sx={{ marginLeft: 'auto' }}
        startIcon={<ChevronRightOutlined />}
        onClick={skipProductSelection}
      >
        {t('skip')}
      </Button>
      <Grid
        container
        spacing={2}
      >
        {data?.getOrganisationFeeProducts?.map(item => (
          <Grid
            item
            md={4}
            key={item.productListItemId}
          >
            <ProductCard
              data={item}
              setIsFreeProductSelected={setIsFreeProductSelected}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
