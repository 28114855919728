// i18n
import { useTranslation } from 'next-i18next';
// next
import Link from 'next/link';
// mui
// store
// types
// utils
import { useQuestionnaireVisibility } from '@/hooks/response/useQuestionnaireVisibility';
import { Routes } from '@/utils/urls';
import { ChevronRight } from '@mui/icons-material';
import { Button, Grid, GridProps, Stack } from '@mui/material';

export const DynamicQuestionnaireCtaButton = ({ ...rest }: GridProps) => {
  const { t } = useTranslation('questionnaire', { keyPrefix: 'questionnaire.progressButton' });

  const { isStatusAvailable, isQuestionnaireInProgress, isQuestionnaireInAmendments, isQuestionnaireSubmitted } =
    useQuestionnaireVisibility();

  return (
    <Grid {...rest}>
      <Stack>
        {isStatusAvailable && (
          <Button
            variant="contained"
            LinkComponent={Link}
            href={Routes.Questionnaire.Root}
          >
            {isQuestionnaireInProgress
              ? t('continue')
              : isQuestionnaireSubmitted
                ? t('view')
                : isQuestionnaireInAmendments
                  ? t('edit')
                  : t('start')}
            <ChevronRight fontSize="small" />
          </Button>
        )}
      </Stack>
    </Grid>
  );
};
