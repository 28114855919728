export enum InvoiceStatus {
  PAID = 'paid',
  UNPAID = 'unpaid',
  SKIPPED = 'skipped',
  PARTIAL = 'partial',
  FREE = 'free',
  FAILED = 'failed',
}

export enum PaymentType {
  CARD = 'card',
  INVOICE = 'invoice',
}

export enum InvoiceTypeEnum {
  ADMIN_FEE = 'admin_fee',
  MEMBERSHIP = 'membership',
}
