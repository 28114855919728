export enum AuthorityTagTypes {
  NO_LIST = 'NoList',
  CURRENT_LIST = 'CurrentList',
  EMPTY_LIST = 'EmptyList',
  PREVIOUS_LIST = 'PreviousList',
  UNKNOWN_STATE = 'UnknownState',
}

export type UnknownState = { _tag: AuthorityTagTypes.UNKNOWN_STATE };

export type NoList = {
  _tag: AuthorityTagTypes.NO_LIST;
  nextListYear: number;
  nextListDeadline: Date;
  nextListSubmitDueDate: Date;
  organisationId: string;
};

export type EmptyList = {
  _tag: AuthorityTagTypes.EMPTY_LIST;
  currentYear: number;
  discloserCycleName: string;
  organisationId: string;
};

export type PreviousList = {
  _tag: AuthorityTagTypes.PREVIOUS_LIST;
  prevListYear: number;
  prevListSubmitDueDate: Date;
  prevListDeadline: Date;
  invitedOrganisationsCount: number;
  submittedResponseRate: number;
  status: string;
  totalOrganisationCount: number;
  disclosureCycleId: string;
  discloserListId: string;
  organisationId: string;
};

export type CurrentList = {
  _tag: AuthorityTagTypes.CURRENT_LIST;
  currentListYear: number;
  currentListSubmitDueDate: Date;
  currentListDeadline: Date;
  invitedOrganisationsCount: number;
  submittedResponseRate?: number | null;
  status: string;
  totalOrganisationCount: number;
  disclosureCycleId: string;
  discloserListId: string;
  organisationId: string;
};

export type PageData = UnknownState | NoList | EmptyList | PreviousList | CurrentList;
